'use client';

import { Box } from '@chakra-ui/react';

import LoadingSpinner from '@components/LoadingSpinner';

export default function Page() {
  return (
    <Box
      bg="white"
      h="100vh"
      left={0}
      overflow="hidden"
      position="absolute"
      top={0}
      w="100vw"
      zIndex={9999}
    >
      <Box
        left="50%"
        position="absolute"
        top="50%"
        transform="translate(-50%, -50%)"
      >
        <LoadingSpinner />
      </Box>
    </Box>
  );
}
