'use client';

import { Spinner } from '@chakra-ui/react';

export default function LoadingSpinner(): JSX.Element {
  return (
    <Spinner
      color="brand.500"
      emptyColor="gray.300"
      size="lg"
      thickness="2px"
    />
  );
}
